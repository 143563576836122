@import url("https://fonts.googleapis.com/css2?family=Poppins&family=Roboto:wght@100;900&display=swap");

/* Style the navbar */
.navbar {
  /* overflow: hidden; */
  background-color: white;
  z-index: 1100 !important;
}

.imgOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(67, 94, 229, 0.4);
}

/* Navbar links */
#navbar a {
  float: left;
  display: flex;
  color: #f2f2f2;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

/* Style the mobileMenu */
.mobileMenu {
  background-color: #2f4076;
  width: 100%;
  padding-top: 25px;
  text-align: left;
}

/* Style the desktopMenu */
#productsmenu {
  z-index: 1 !important;
}

/* Page content */
.content {
  padding: 16px;
}

/* The sticky class is added to the navbar with JS when it reaches its scroll position */
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

.stickyLeftMenu {
  position: fixed;
  top: 70px;
  z-index: -100 !important;
  width: "auto";
}

.stickyShopMenu {
  position: fixed;
  top: 60px;
  z-index: -1;
  width: 110% !important;
  margin-left: -25px;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the navigation bar gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
  padding-top: 60px;
}

.root {
  --homeUrl: url("");
}

.back-image {
  background-image: var(--homeUrl);
  background-color: rgb(0, 0, 0);
  height: 400px;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: cover;
}

.back-color {
  background: rgb(85, 229, 255);
  background: -moz-linear-gradient(
    132deg,
    rgba(85, 229, 255, 1) 0%,
    rgba(0, 222, 193, 1) 88%,
    rgba(5, 218, 190, 1) 96%
  );
  background: -webkit-linear-gradient(
    132deg,
    rgba(85, 229, 255, 1) 0%,
    rgba(0, 222, 193, 1) 88%,
    rgba(5, 218, 190, 1) 96%
  );
  background: linear-gradient(
    132deg,
    rgba(85, 229, 255, 1) 0%,
    rgba(0, 222, 193, 1) 88%,
    rgba(5, 218, 190, 1) 96%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#55e5ff",endColorstr="#05dabe",GradientType=1);

  height: 300px;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: cover;
  margin-bottom: -25px;
}

.back-color2 {
  background: rgb(59, 95, 225);
  background: linear-gradient(
    83deg,
    rgba(59, 95, 225, 1) 0%,
    rgba(113, 165, 237, 1) 25%,
    rgba(47, 211, 201, 1) 50%,
    rgba(53, 217, 73, 1) 75%,
    rgba(173, 232, 140, 1) 100%
  );

  height: 300px;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-origin: padding-box;
  background-clip: border-box;
  background-size: cover;
  margin-bottom: -25px;
}

.title-text {
  color: white;
  display: block;
  padding-top: 3%;
  font-size: 1.6rem;
}

.pointer {
  cursor: pointer;
}

.sitemap {
  text-align: left;
}

.imgBanner {
  align-content: "center";
  max-width: 80%;
}

.imgapps {
  align-content: "center";
  max-width: 100%;
}

@media only screen and (max-width: 768px) {
  .back-image {
    background-image: var(--homeUrl);
    background-color: rgb(0, 0, 0);
    height: 300px;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-origin: padding-box;
    background-clip: border-box;
    background-size: cover;
  }

  .back-color {
    background: rgb(85, 229, 255);
    background: -moz-linear-gradient(
      132deg,
      rgba(85, 229, 255, 1) 0%,
      rgba(0, 222, 193, 1) 88%,
      rgba(5, 218, 190, 1) 96%
    );
    background: -webkit-linear-gradient(
      132deg,
      rgba(85, 229, 255, 1) 0%,
      rgba(0, 222, 193, 1) 88%,
      rgba(5, 218, 190, 1) 96%
    );
    background: linear-gradient(
      132deg,
      rgba(85, 229, 255, 1) 0%,
      rgba(0, 222, 193, 1) 88%,
      rgba(5, 218, 190, 1) 96%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#55e5ff",endColorstr="#05dabe",GradientType=1);

    height: 200px;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-origin: padding-box;
    background-clip: border-box;
    background-size: cover;
    margin-bottom: -25px;
  }

  .back-color2 {
    background: rgb(59, 95, 225);
    background: linear-gradient(
      83deg,
      rgba(59, 95, 225, 1) 0%,
      rgba(113, 165, 237, 1) 25%,
      rgba(47, 211, 201, 1) 50%,
      rgba(53, 217, 73, 1) 75%,
      rgba(173, 232, 140, 1) 100%
    );

    height: 200px;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-origin: padding-box;
    background-clip: border-box;
    background-size: cover;
    margin-bottom: -25px;
  }

  .title-text {
    color: white;
    display: block;
    /* padding-top: 5%; */
    font-size: 1rem;
  }
  .sitemap {
    text-align: center;
  }
  .imgBanner {
    align-content: "center";
    max-width: 100%;
  }

  #mobileproductTitle {
    padding: 0px;
    text-align: center !important;
  }

  #mobileproductName {
    padding: 0px;
    text-align: center !important;
    z-index: 1;
  }

  #mobileProImg {
    margin-top: -10px;
    margin-bottom: -30px;
    /* padding: 10px; */
  }

  #mobileproductlistid {
    margin-top: -10px !important;
  }

  #mobileViewProductName {
    margin-bottom: 20px;
  }

  #mobileView {
    padding: 0px !important;
  }

  #mobileAfrekenenCheckout {
    padding: 10px !important;
  }

  #mobileafrekeknenviewtitle {
    text-align: center;
  }

  #imgwasabonemobileview {
    margin-left: -20px !important;
  }

  /* #mobileLoader{
    margin:0px !important;
  } */

  /* .stickyShopMenu {
    position: fixed;
    top: 160px;
    z-index: -1;
    width: 110% !important;
    margin-left: -25px;
  } */
}

/* #mobileView{
  padding: 50px;
} */

.coralGreen {
  background: rgb(85, 229, 255);
  background: -moz-linear-gradient(
    132deg,
    rgba(85, 229, 255, 1) 0%,
    rgba(0, 222, 193, 1) 88%,
    rgba(5, 218, 190, 1) 96%
  );
  background: -webkit-linear-gradient(
    132deg,
    rgba(85, 229, 255, 1) 0%,
    rgba(0, 222, 193, 1) 88%,
    rgba(5, 218, 190, 1) 96%
  );
  background: linear-gradient(
    132deg,
    rgba(85, 229, 255, 1) 0%,
    rgba(0, 222, 193, 1) 88%,
    rgba(5, 218, 190, 1) 96%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#55e5ff",endColorstr="#05dabe",GradientType=1);
}

.gradientBG {
  background: rgb(59, 95, 225);
  background: linear-gradient(
    83deg,
    rgba(59, 95, 225, 1) 0%,
    rgba(113, 165, 237, 1) 25%,
    rgba(47, 211, 201, 1) 50%,
    rgba(53, 217, 73, 1) 75%,
    rgba(173, 232, 140, 1) 100%
  );
}

.gradientBGRV {
  background: rgb(59, 95, 225);
  background: linear-gradient(
    278deg,
    rgba(59, 95, 225, 1) 0%,
    rgba(113, 165, 237, 1) 25%,
    rgba(47, 211, 201, 1) 50%,
    rgba(53, 217, 73, 1) 75%,
    rgba(173, 232, 140, 1) 100%
  );
}
a {
  text-decoration: none;
}
/* A link that has not been visited */
a:link {
  color: #2f4076;
}
/* A link that has been visited */
a:visited {
  color: #345ee4;
}
/* A link that is hovered on */
a:hover {
  color: "#6e6ca0";
}

.scities p {
  line-height: 26px;
  color: #7d8183;
  letter-spacing: 0.5px;
  font-weight: 400;
}
